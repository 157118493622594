<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    width="600"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      v-if="dialogVisible"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item label="名称：" prop="fileName">
        <el-input v-model="ruleForm.fileName"></el-input>
      </el-form-item>
      <el-form-item label="图片" prop="fileUrl">
        <el-upload
          class="avatar-uploader"
          action="https://jsonplaceholder.typicode.com/posts/"
          :show-file-list="false"
          :auto-upload="false"
          :on-change="handlePreview"
          accept="image/*"
          :on-remove="handleRemove"
        >
          <img v-if="imageUrl" :src="imageUrl" class="avatar" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="排序：" prop="sort">
        <el-input v-model="ruleForm.sort" type="number"></el-input>
      </el-form-item>
      <el-form-item label="启用" prop="isEnabled">
        <el-radio-group v-model="ruleForm.isEnabled">
          <el-radio :label="1">是</el-radio>
          <el-radio :label="0">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >确 定</el-button
        >
        <el-button @click="resetForm('ruleForm')">重 置</el-button>
      </el-form-item>
    </el-form>
    <recommendDialog
      :recommendId="ruleForm.referrerUniqueId"
      @changeID="handleSetRuleForm"
      v-model="isAllocationCustomer"
    />
    <!-- <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="handleClose" size="mini">确 定</el-button>
      <el-button @click="handleClose" size="mini">重 置</el-button>
    </span>-->
    <input
      type="file"
      name=""
      id=""
      ref="clickImage"
      style="width: 0; height: 0"
      accept="image/jpeg,image/jpg,image/png"
      @change="changeImage"
    />
  </el-dialog>
</template>

<script>
import {
  listTenant,
  customerUpdate,
  customerAdd,
  getOwnBusiness,
} from "@/api/SystemData";
import { saveBanner } from "@/api/policy";
import { OSSDirectPass } from "@/api/oss.js";
import VDistpicker from "v-distpicker";
import recommendDialog from "@/views/SystemManagement/components/customer/recommendDialog.vue";
import { phoneFun, fileToBase64 } from "@/utils/index";
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    editData: {
      type: Object,
      default: () => {},
    },
    userTypes: {
      type: Array,
      default: () => [],
    },
  },
  components: { VDistpicker, recommendDialog },
  watch: {
    dialogVisible: {
      handler(v) {
        if (v && this.editData.id) {
          this.ruleForm = JSON.parse(JSON.stringify(this.editData));
          this.ruleForm.fileUrl ? (this.imageUrl = this.ruleForm.fileUrl) : "";
        } else if (!this.editData.id) {
          this.imageUrl = "";
          this.imageFile = "";
          this.ruleForm = {
            fileName: "",
            fileSize: "",
            fileUrl: "",
            isEnabled: 1,
            sort: 1,
          };
          this.fileObj = null;
          //  this.$refs.clickImage.value = '';
        }
      },
      deep: true,
    },
  },
  data() {
    var validateMobile = (rule, value, callback) => {
      if (!phoneFun(value) && value) {
        callback(new Error("请输入密码"));
      } else if (!value) {
        callback(new Error("请输入密码"));
      } else {
        // this.$refs.ruleForm.validateField('checkPass');
        callback();
      }
    };
    return {
      ruleForm: {
        fileName: "",
        fileSize: "",
        fileUrl: "",
        isEnabled: 1,
        sort: 1,
      },
      rules: {
        fileUrl: [{ required: true, message: "请选择图片", trigger: "change" }],
      },
      loading: true,
      options: [],
      fileObj: null,
      recommendId: "",
      isAllocationCustomer: false,
      passwordType: "password",
      imageUrl: "",
      imageFile: "",
      referrerList: [],
      cardNoTime: null,
    };
  },
  async created() {
    // this.loading = true;
    // let list = await this.initList();
    // this.loading = false;
    // else {
    //   this.options = [];
    // }
  },
  methods: {
    initList(query = "") {
      return new Promise((resolve, reject) => {
        listTenant({
          tenantName: query,
        })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(false);
          });
      });
    },
    handleClose() {
      this.$emit("update:dialogVisible", false);
    },
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let obj = {};
          if (this.imageUrl && this.imageFile) {
            let ossUrl = await OSSDirectPass(this.imageFile);
            obj.fileUrl = ossUrl;
            let kbNum = (this.imageFile.size / 1024).toFixed(1);
            obj.fileSize =
              (kbNum > 1024 ? (kbNum / 1024).toFixed(1) : kbNum) +
              (kbNum > 1024 ? "MB" : "KB");
          }
          saveBanner({ ...this.ruleForm, ...obj }).then((res) => {
            this.handleClose();
            this.$emit("changeList");
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(type) {
      if (this.editData.id) {
        this.ruleForm = JSON.parse(JSON.stringify(this.editData));
      } else {
        this.ruleForm = {
          fileName: "",
          fileSize: "",
          fileUrl: "",
          isEnabled: 1,
          sort: 1,
        };
        this.fileObj = null;
      }
    },
    async remoteMethod(query) {
      this.loading = true;
      let list = await this.initList(query);
      this.loading = false;
      if (list) {
        this.options = list;
      } else {
        this.options = [];
      }
    },
    onSelectedDist(e, type) {
      if (type == "single") {
        this.ruleForm.regionArea = "";
        return false;
      } else if (e.province) {
        this.ruleForm.regionProvince = e.province.value;
        this.ruleForm.regionCity = e.city.value;
        this.ruleForm.regionArea = e.area.value;
      }
    },
    changeImage(e) {
      var file = e.target.files[0];
      var reader = new FileReader();
      var that = this;
      reader.readAsDataURL(file);
      this.fileObj = { name: file.name, raw: file };
      console.log(this.fileObj, file);
      reader.onload = function (res) {
        that.ruleForm.avatar = this.result;
      };
    },
    handleSetRuleForm(e) {
      this.isAllocationCustomer = false;
      e.referrerName = e.referrerUserName;
      Object.assign(this.ruleForm, e);
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    async handlePreview(file) {
      this.imageFile = file;
      this.ruleForm.fileUrl = "22";
      this.imageUrl = await fileToBase64(file.raw);
    },
    handleChangeCustomerType(e) {
      let data = this.userTypes.find((val) => val.value == e);
      this.ruleForm.cardType = data.text == "个体客户" ? 2 : 1;
      this.ruleForm.cardTypeDesc =
        data.text == "个体客户" ? "身份证" : "统一社会信用代码";
      this.customerTypeDesc = data.text;
    },
    handleChangeReferrer(e) {
      let data = this.referrerList.find((val) => val.salesmanName == e);
      this.ruleForm.referrerId = data.uniqueId;
    },
    handlecardNoInput(e) {
      clearTimeout(this.cardNoTime);
      this.cardNoTime = null;
      if (e) {
        this.cardNoTime = setTimeout(() => {
          getOwnBusiness({
            cardNo: e,
          }).then((res) => {
            this.referrerList = res.data;
          });
          clearTimeout(this.cardNoTime);
          this.cardNoTime = null;
        }, 700);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  background: #4b7bca;
  .el-dialog__title {
    color: #fff;
  }
}
.reset-password {
  display: flex;
}
.avatar-uploader {
  ::v-deep .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  ::v-deep .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
