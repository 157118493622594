<template>
  <el-dialog
    width="60%"
    :title="title"
    :visible.sync="dialogVisible"
    custom-class="caluse-dialog"
    :close-on-click-modal="false"
    append-to-body
  >
    <div class="form-box">
      <el-select
        v-model="formData.tenantId"
        style="width: 490px"
        v-if="$store.state.tenantId === '000000'"
        filterable
        @change="handleTenant"
        placeholder="请选择"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.text"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-input
        v-else
        placeholder="请输入内容"
        v-model="$store.state.userInfo.name"
        disabled
        style="width: 490px; margin-right: 10px"
      ></el-input>
      <div>
        <el-input
          placeholder="请输入内容"
          prefix-icon="el-icon-search"
          style="width: 180px; margin-right: 10px"
          @input="handleInput"
          v-model="formData.fastLike"
        >
        </el-input>
        <el-button
          type="primary"
          icon="el-icon-refresh"
          size="small"
          @click="getList"
        ></el-button>
      </div>
    </div>
    <el-table
      ref="singleTable"
      :data="tableData"
      highlight-current-row
      @current-change="handleCurrentChangeRow"
      style="width: 100%"
    >
      <el-table-column type="index" label="序号" width="50"> </el-table-column>
      <el-table-column property="userId" label="用户ID"> </el-table-column>
      <el-table-column property="nickname" label="名称"> </el-table-column>
      <el-table-column property="phoneNum" label="手机号"> </el-table-column>
      <el-table-column property="deptName" label="所属部门"> </el-table-column>
      <el-table-column property="postName" label="岗位"> </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="formData.current"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="formData.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="handleSubmit">确 定</el-button>
      <el-button @click="handleReset">重 置</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { referrerPage, policyBindUser, enquiryBindUser } from "@/api/policy";
export default {
  props: {
    showDialog: Boolean,
    ids: {
      type: Array,
      default: () => [],
    },
    apiType: {
      type: String,
      default: "policy",
    },
    recommendId: {
      type: [String, Number],
      default: "",
    },
    title: {
      type: String,
      default: "选择推荐人",
    },
  },
  model: {
    prop: "showDialog",
    event: "change",
  },
  watch: {
    dialogVisible: {
      handler(v) {
        if (!v) {
          this.$emit("change", false);
        } else {
          this.$emit("change", true);
        }
      },
    },
    showDialog: {
      handler(v) {
        if (v) {
          this.dialogVisible = true;
          this.currentRow = "";
          this.formData.size = 10;
          this.formData.current = 1;
          if (this.recommendId) {
            this.formData.tenantId = this.recommendId;
            this.getList();
          } else {
            this.formData.tenantId = this.$store.state.tenantId;
            this.tableData = [];
            if (this.$store.state.tenantId !== "000000") {
              this.getList();
            }
          }
        } else {
          this.dialogVisible = false;
        }
      },
    },
  },
  data() {
    return {
      dialogVisible: false,
      options: [],
      value: "",
      tableData: [],
      currentRow: "",
      formData: {
        current: 1,
        size: 10,
        fastLike: "",
        tenantId: "",
      },
      total: 0,
      timeObj: null,
    };
  },
  created() {
    if (this.$store.state.tenantId === "000000") {
      this.options = JSON.parse(JSON.stringify(this.$store.state.tenantList));
      this.options.splice(0, 1);
    } else {
      this.formData;
    }
  },
  computed: {},
  methods: {
    getList() {
      referrerPage(this.formData)
        .then((res) => {
          this.tableData = res.data.records;
          this.total = res.data.total;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleCurrentChangeRow(val) {
      console.log(val);
      this.currentRow = val;
    },
    handleSizeChange(val) {
      this.formData.size = val;
      this.formData.current = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.formData.current = val;
      this.getList();
    },
    handleInput() {
      clearTimeout(this.timeObj);
      this.timeObj = setTimeout(() => {
        clearTimeout(this.timeObj);
        this.formData.current = 1;
        this.getList();
      }, 500);
    },
    handleSubmit() {
      if (!this.currentRow) {
        this.$message.error("请选择推荐人");
        return false;
      }
      this.$emit("changeID", {
        referrerUniqueId: this.formData.tenantId,
        referrerUserId: this.currentRow.userId,
        referrerUserName: this.currentRow.nickname,
        referrerId: this.currentRow.uniqueId,
      });
      this.dialogVisible = false;
    },
    handleReset() {
      this.formData.size = 10;
      this.formData.current = 1;
      this.formData.fastLike = "";
      this.currentRow = "";
      this.recommendId
        ? (this.formData.tenantId = this.recommendId)
        : (this.formData.tenantId = "");
      this.getList();
    },
    handleTenant(e) {
      this.formData.tenantId = e;
      this.getList();
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .caluse-dialog {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  .el-dialog__header {
    font-size: 18px;
    font-weight: bold;
    height: 40px;
    line-height: 40px;
    padding: 0 24px;
    background: #4278c9;
    .el-dialog__title {
      color: #fff;
    }
    .el-dialog__headerbtn {
      top: 11px;
      .el-dialog__close {
        color: #fff;
        font-weight: bold;
      }
    }
  }
  .el-dialog__body {
    overflow: auto;
    padding: 24px;
  }
}
.form-box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
</style>
